main {
  text-align: center;
  vertical-align: center;
  line-height: 100vh
}

.flex-wrapper {
  display: flex;
  min-height: calc(100vh - 73px);
  flex-direction: column;
  justify-content: flex-start;
}

.verticalScroller {
  overflow-y: auto !important;
}

.verticalScroller::-webkit-scrollbar {
  width: 7px;
}


.verticalScroller::-webkit-scrollbar-track {
  background: transparent;
}


.verticalScroller::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}


.verticalScroller::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.verticalScroller::-webkit-scrollbar:hover {
  width: 10px !important;
}


